<template>
  <contentCard titleText="订单详情">
    <div class="overflow-scroll">
      <div class="order-no-text">订单编号：{{ orderForm.orderId }}</div>
      <div class="module-title">餐品信息</div>
      <div class="dish-list">
        <div class="dish-item" v-for="(item, index) in dishPacList" :key="'dishItem' + index">
          <div class="dish-picture">
            <img v-if="item.imageUrl" class="img" :src="item.imageUrl" />
            <img v-else class="img" src="./img/zwtp.png" />
          </div>
          <div class="dish-info-box">
            <div class="dish-name-text">{{ ['', void 0, null, NaN].includes(item.dishId) ? item.packageName : item.dishName }}</div>
            <div class="quantity-text">数量 × {{ item.quantity }}</div>
            <div class="price-text"><span>¥</span>{{ item.price }}</div>
          </div>
        </div>
      </div>
      <div class="module-title">配送信息</div>
      <div class="order-form-box">
        <div class="order-form-item">
          <label for="userName">订餐人：</label>
          <input class="order-form-input" type="text" placeholder="请输入订餐人" id="userName" v-model="orderForm.userName" readonly />
        </div>
        <div class="order-form-item">
          <label for="mobileNum">联系电话：</label>
          <input class="order-form-input" type="text" placeholder="请输入联系电话" id="mobileNum" v-model="orderForm.mobileNum" readonly />
        </div>
        <div class="order-form-item">
          <label for="houseAddress">取餐地址：</label>
          <input
            class="order-form-input have-right-icon"
            type="text"
            placeholder="请选择取餐地址"
            id="houseAddress"
            v-model="orderForm.houseAddress"
            readonly
            @click="houAddPopShow = true"
          />
          <van-icon class="right-icon" name="arrow-down" size="16px" color="#858585" />
        </div>
        <div class="order-form-item">
          <label for="qcsjText">取餐时间：</label>
          <input
            class="order-form-input have-right-icon"
            type="text"
            placeholder="请输入取餐时间"
            id="qcsjText"
            v-model="qcsjText"
            readonly
          />
          <van-icon class="right-icon" name="arrow-down" size="16px" color="#858585" />
        </div>
      </div>
    </div>
  </contentCard>
</template>

<script>
import contentCard from '../components/contentCard.vue';
import { getMyOrderDetailUrl } from './api.js';
import Big from 'big.js';
import Vue from 'vue';
import { Icon } from 'vant';
import { mapState } from 'vuex';

Vue.use(Icon);

export default {
  name: 'ytjEldCanConfirmPlaceOrder',
  components: {
    contentCard
  },
  data() {
    return {
      dishPacList: [],
      orderForm: {
        tenantId: '',
        userId: '',
        userName: '',
        mobileNum: '',
        spaceId: '',
        spacePath: '',
        houseAddress: '',
        orderDishes: '',
        orderAmount: void 0,
        deliveryTime: null,
        supplyTimeStart: null,
        supplyTimeEnd: null
      }
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId']),
    qcsjText() {
      if (this.orderForm.supplyTimeStart && this.orderForm.supplyTimeEnd) {
        return (
          this.orderForm.deliveryTime + ' ' + this.orderForm.supplyTimeStart.slice(0, 5) + '~' + this.orderForm.supplyTimeEnd.slice(0, 5)
        );
      } else {
        return this.orderForm.deliveryTime + ' 全天';
      }
    },
    totalPrice() {
      return this.dishPacList
        .map(item => Big(item.quantity).times(item.price).toNumber())
        .reduce((prev, next) => Big(prev).plus(next).toNumber(), 0);
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (!this.$route.query.id) return;
      this.$axios
        .get(getMyOrderDetailUrl, { params: { id: this.$route.query.id } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.orderDetailList = Array.isArray(res.data.orderDetailList) ? res.data.orderDetailList : [];
            this.orderForm = res.data;
            this.dishPacList = res.data.orderDetailList;
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .content-body {
  padding: 0px 40px 30px 40px;
}
.overflow-scroll {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.order-no-text {
  box-sizing: border-box;
  height: 48px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 25px;
  color: #1874fd;
  border: 1px solid #1874fd;
  background-color: rgba(24, 116, 253, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.module-title {
  box-sizing: border-box;
  font-size: 18px;
  height: 25px;
  line-height: 25px;
  color: #2f3a4f;
  font-weight: 600;
}
.dish-list {
  box-sizing: border-box;
  width: 100%;
  height: 110px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  .dish-item {
    box-sizing: border-box;
    flex-shrink: 0;
    margin-right: 20px;
    display: flex;
    height: 100%;
    .dish-picture {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 180px;
      height: 100%;
      border-radius: 4px;
      margin-right: 10px;
      .img {
        box-sizing: border-box;
        border-radius: inherit;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .dish-info-box {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      padding-top: 5px;
      .dish-name-text {
        max-width: 100px;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        font-size: 15px;
        line-height: 21px;
        color: #333333;
        margin-bottom: 5px;
      }
      .quantity-text {
        font-size: 13px;
        line-height: 18px;
        color: #333333;
      }
      .price-text {
        font-size: 24px;
        line-height: 33px;
        color: #e02020;
        span {
          font-size: 16px;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.order-form-box {
  box-sizing: border-box;
  margin-top: 10px;
  // height: calc(100% - (48px + 10px + 25px + 10px + 110px + 10px + 25px + 10px));
  // overflow-x: hidden;
  // overflow-y: auto;
  .order-form-item {
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    padding-bottom: 15px;
    position: relative;
    display: flex;
    label {
      box-sizing: border-box;
      flex-shrink: 0;
      color: #2f3a4f;
      line-height: 50px;
      width: 104px;
    }
    .order-form-input {
      box-sizing: border-box;
      height: 50px;
      border: 1px solid #e4e7ed;
      border-radius: 6px;
      width: calc(100% - 104px);
      line-height: 48px;
      padding: 0px 16px;
      color: #262626;
      &.have-right-icon {
        padding-right: 48px;
      }
    }
    .right-icon {
      position: absolute;
      right: 16px;
      top: 17px;
    }
  }
}
</style>
